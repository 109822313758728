










































































































































@use "@material/checkbox"; // Required only for data table with row selection.
@use "@material/icon-button"; // Required only for data table with column sorting.
@use "@material/data-table/data-table";

@include checkbox.core-styles;
@include icon-button.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;
.header{

}
.preference-button{
  font-size:13px !important;
  margin-left:200px !important;
  margin-top: 20px;
}
#update-portfolio{
  margin-top: 30px;
  margin-left: 30px;
}
.preference-button-balance{
  margin-top: 20px;
  font-size:13px !important;
  margin-left:200px !important;
}
.select-portfolio{
  width: 300px;
  margin-top: 20px;
  margin-left: 200px;
}
.title{
  margin-left: 200px;
}
.preference-button-value-list{
  margin-top: 20px;
  font-size:13px !important;
  margin-left:10px !important;
}
.preference-button-dinamic-graficator{
  margin-top: 20px;
  font-size:13px !important;
  margin-left:10px !important;
}
.input-disabled :hover{
  cursor: not-allowed;
}
.input-disabled{
  margin-right: 200px;
  margin-left: 200px;
}
.mid-info{
  margin-right: 200px;
  margin-left: 200px;
}
a{
  text-decoration: none;
}
